@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: #7EFCDE;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
.container{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: grid;
    place-content: center;

}
.content{
display: block;
gap: 5em;
width: 100%;
padding-top: 1em;
position: relative;
}
.content:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #7EFCDE;
    transform: scaleX(1);
}
h1{
    font-size: 3rem;
    width: 80vw;
    line-height: 100%;
    text-align: center;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}
h1:before{
   content:'<'; 
}
h1:after{
    content:'/>'; 
 }
h1 .human{
    color: #fff!important;
}

h1, .logo, .movil, .mail{
    flex-basis: 0;
    flex-grow: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}
.movil{
    margin-top: 40px;
}
.contentA{
    display: block;
    gap: 5em;
    width: 100%;
    padding-bottom: 4em;
    position: relative;
    }

p{
    text-align: center;
    color: #D2DAF6;
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
}
.logo {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    text-align: center;
    position: absolute;
    /* height: 300px; */
    top: -120px;
}

@media screen and (max-width: 600px) {
    .logo {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        left: 0px;
        right: 0px;
        text-align: center;
        position: absolute;
        /* height: 300px; */
        top: -80px;
    }
    h1{
        font-size: 1.3rem;
        width: 95vw;
        line-height: 110%;
        text-align: center;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
    }
  }